<template>
  <div :class="$style.info">
    <a-row :gutter="16" :class="$style.infoItem">
      <a-col :span="12">
        <div :class="$style.textHidden">
          <span :class="$style.text">{{ $t('safety.deviceName') }}：</span>
          <span :title="headerData.deviceName">
            {{ headerData.deviceName }}
          </span>
        </div>
      </a-col>
      <a-col :span="12">
        <div :class="$style.textHidden">
          <span :class="$style.text">{{ $t('safety.deviceNumber') }}：</span>
          <span>
            {{ headerData.deviceCode }}
          </span>
        </div>
      </a-col>
    </a-row>
    <a-row :gutter="16" :class="$style.infoItem">
      <a-col :span="12">
        <div :class="$style.textHidden">
          <span :class="$style.text">{{ $t('safety.bindProject') }}：</span>
          <span :title="headerData.projectName">
            {{ headerData.projectName }}
          </span>
        </div>
      </a-col>
      <a-col :span="12">
        <div :class="$style.textHidden">
          <span :class="$style.text">{{ $t('safety.bindDate') }}：</span>
          <span>
            {{ formateTime(headerData.bindTime) }}
          </span>
        </div>
      </a-col>
    </a-row>
    <a-row :class="$style.infoItem">
      <a-col>
        <div :class="$style.textHidden">
          <span :class="$style.text">{{ $t('common.remark') }}：</span>
          <span :title="headerData.remark">
            {{ headerData.remark }}
          </span>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component()
export default class DetailHeader extends Vue {
  @Prop({ type: Object, default: () => {} }) headerData;

  formateTime(t) {
    return t ? this.dayjs(t).format('YYYY-MM-DD HH:mm:ss') : '';
  }
}
</script>

<style lang="less" module>
.info {
  margin: 20px 20px 0;
  padding: 10px 20px;
  border-radius: 6px;
  box-shadow: 0 0 6px 1px rgba(144, 144, 144, 0.3);

  .infoItem {
    padding: 5px 0;
  }
}
.text {
  color: var(--font-info);
}
.textHidden {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
