<template>
  <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
    <a-row :gutter="24" class="form-row-margin">
      <a-col :span="12">
        <a-form-model-item :label="$t('safety.deviceName')" prop="deviceName">
          <a-input
            :placeholder="$t('safety.inputDeviceName')"
            v-model="form.deviceName"
            :maxLength="32"
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="12">
        <a-form-model-item :label="$t('safety.deviceCode')" prop="deviceCode">
          <a-input
            :placeholder="$t('safety.inputDeviceCode')"
            v-model="form.deviceCode"
            :disabled="!canEdit"
            :maxLength="128"
          />
        </a-form-model-item>
      </a-col>
    </a-row>
    <a-row :gutter="24" class="form-row-margin">
      <a-col :span="12">
        <a-form-model-item label="设备密钥" prop="deviceKey">
          <a-input
            placeholder="请输入设备密钥"
            v-model="form.deviceKey"
            :disabled="!canEdit"
            :maxLength="16"
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="12">
        <a-form-model-item :label="$t('safety.belongProject')" prop="projectId">
          <a-select
            allowClear
            v-model="form.projectId"
            :placeholder="$t('safety.chooseProject')"
            @change="handleProjectChange"
          >
            <a-select-option
              :value="item.pkId"
              v-for="item in projectLists"
              :key="item.pkId"
            >
              {{ item.projectName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
    </a-row>
    <a-row :gutter="24">
      <a-col :span="12">
        <a-form-model-item :label="$t('common.remark')" prop="remark">
          <a-input
            :placeholder="$t('safety.inputRemark')"
            v-model="form.remark"
            :maxLength="128"
          />
        </a-form-model-item>
      </a-col>
    </a-row>
  </a-form-model>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { addDevice, modifyDevice } from '@/services/device-manage/device';
import { deviceTypeEnum } from '@/enum/device';

@Component()
export default class AddEditDevice extends Vue {
  @Prop({ type: Object, default: () => {} }) editData;
  @Prop({ type: Array, default: () => [] }) projectList;
  @Prop({ type: String, default: '' }) pageType;
  @Prop({ type: String, default: '' }) operationFlag;
  form = {
    deviceName: '',
    deviceCode: '',
    projectId: undefined,
    deviceKey: '',
    remark: '',
  };
  rules = {
    deviceName: [
      {
        required: true,
        message: this.$t('safety.requiredDeviceName'),
      },
    ],
    deviceCode: [
      {
        required: true,
        message: this.$t('safety.requiredDeviceCode'),
      },
    ],
    deviceKey: [
      {
        required: true,
        message: '设备密钥不能为空',
      },
    ],
  };
  canEdit = true;
  mounted() {
    this.initData();
  }
  deviceId = '';
  initData() {
    this.deviceId = '';
    if (this.operationFlag === 'edit') {
      const data = this.editData;
      this.canEdit = false;
      this.deviceId = data.pkId;
      this.form.deviceName = data.deviceName;
      this.form.deviceCode = data.deviceCode;
      this.form.projectId = data.idxProjectId;
      this.form.remark = data.remark;
      this.form.deviceKey = data.encryptionKey;
    }
  }
  handleProjectChange(projectId) {
    this.form.projectId = projectId;
  }
  // @Debounce(300)
  // filterProjectOption(input) {
  //   this.projectSearchInput = input.toLowerCase();
  //   this.getProjectList();
  // }
  // 获取项目列表的搜索
  // projectSearchInput = '';
  get projectLists() {
    return this.projectList;
  }
  get deviceGroup() {
    return deviceTypeEnum[this.pageType].deviceGroup;
  }
  get deviceType() {
    return deviceTypeEnum[this.pageType].deviceType;
  }
  getValue() {
    return new Promise((resolve, reject) => {
      this.$refs.form.validate(async valid => {
        if (valid) {
          const flag = this.operationFlag === 'add';
          const params = {
            deviceType: ['PROTECTION_NET'].includes(this.deviceType)
              ? ''
              : this.deviceType,
            deviceName: this.form.deviceName,
            deviceCode: this.form.deviceCode,
            idxProjectId: this.form.projectId,
            remark: this.form.remark,
            deviceKey: this.form.deviceKey,
          };
          if (!flag) {
            params.deviceId = this.deviceId;
          }
          try {
            const str = flag
              ? this.$t('common.addSuccess')
              : this.$t('common.modifySuccess');
            this.operationFlag === 'add'
              ? await addDevice(params, this.deviceGroup)
              : await modifyDevice(params, this.deviceGroup);
            this.$message.success(str);
            resolve(true);
          } catch {
            reject(false);
          }
        } else {
          reject(false);
        }
      });
    });
  }
}
</script>

<style></style>
