export const pageTypeEnum = {
  /** @name 塔吊安全监测 */
  towerCrane: 'TCSF',
  /** @name 升降机监测 */
  liftMonitor: 'ED',
  /** @name 吊篮监测 */
  nacelleMonitor: 'HBD',
  /** @name 卸料平台监测 */
  unloadMonitor: 'UP',
  /** @name 高支模监测 */
  highFormwork: 'HF',
  /** @name 深基坑监测 */
  pitMonitor: 'DFP',
  /** @name 混凝土监测 */
  concreteMonitor: 'CT',
  /** @name 智能烟感 */
  smokeDetector: 'SMOKE',
  /** @name 螺丝松动监测 */
  screwMonitor: 'SCREW',
  /** @name 智能水表 */
  waterMonitor: 'WM',
  /** @name 智能电表 */
  elecMonitor: 'EM',
  /** @name 水质监测 */
  sewageMonitor: 'SD',
  /** @name 临边防护 */
  edgeProtection: 'PN',
  /** @name AI盒子 */
  aiBox: 'AIV',
  /** @name 标养室监测 */
  standardCuringRoom: 'SCR',
};
