<template>
  <div :class="$style.content">
    <div>
      <a-form-model :class="$style.search" layout="inline" :model="form">
        <div>
          <div :class="$style.item">
            <a-form-model-item :label="$t('safety.device')">
              <a-input
                v-model="form.searchInput"
                allow-clear
                @change="() => handleSearchSubmit()"
                :placeholder="$t('safety.searchNameCode')"
              />
            </a-form-model-item>
          </div>
          <div :class="$style.item">
            <a-form-model-item :label="$t('safety.projectName')">
              <a-select
                v-model="form.projects"
                :placeholder="$t('safety.chooseProject')"
                style="width: 200px;"
                allowClear
                @change="() => handleSearchSubmit()"
              >
                <a-select-option
                  v-for="item in projectLists"
                  :key="item.pkId"
                  :value="item.pkId"
                >
                  {{ item.projectName }}
                </a-select-option>

                <template slot="notFoundContent">
                  <a-empty :image="simpleImage" />
                </template>
              </a-select>
            </a-form-model-item>
          </div>
        </div>
        <div :class="$style.btn">
          <a-button
            type="primary"
            v-if="$p.action('CREATE')"
            @click="addOrEditDevice({}, 'add')"
          >
            {{ $t('safety.addDevice') }}
          </a-button>
        </div>
      </a-form-model>
      <a-table
        :columns="columns"
        :data-source="tableData"
        :pagination="pagination"
        :row-key="record => record.deviceId"
        :transformCellText="({ text }) => text || '-'"
        :loading="loading"
        @change="handlePagination"
      >
        <template slot="action" slot-scope="record">
          <div :class="$style.buttonGroups">
            <span
              :class="[$style.button]"
              v-if="$p.action('UPDATE')"
              @click.stop="addOrEditDevice(record, 'edit')"
            >
              {{ $t('common.edit') }}
            </span>
            <span :class="[$style.button]" @click.stop="showDetail(record)">
              {{ $t('common.detail') }}
              <span
                v-if="record.alarmCount !== 0 && record.alarmCount !== -1"
                :class="$style.messageCount"
              >
                {{ record.alarmCount }}
              </span>
            </span>
            <span
              v-if="showThreshold"
              :class="[$style.button]"
              @click.stop="setThreshold(record)"
            >
              {{ $t('safety.threshold') }}
            </span>
            <slot name="expandButton" :record="record" />
            <span
              :class="[$style.button, $style.red]"
              v-if="$p.action('DELETE')"
              @click.stop="deleteTips(record)"
            >
              {{ $t('common.delete') }}
            </span>
          </div>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Debounce } from 'lodash-decorators';
import { Empty as EmptyObj } from 'ant-design-vue';
import { createFormModal } from '@triascloud/x-components';
import AddOrEditDevice from './add-edit-device';
import AddOrEditThreshold from './add-edit-threshold';
import Tip from '@/components/tip';
import { deviceTypeEnum } from '@/enum/device';
import {
  deleteDeviceById,
  deviceList,
  projectList,
} from '@/services/device-manage/device';
import { PublicSize } from '@/views/green-construction/components/utils';

@Component({
  components: {
    AddOrEditDevice,
  },
})
export default class CommonIndex extends Vue {
  @Prop({ type: String, default: '' }) pageType;
  @Prop({ type: Boolean, default: false }) showThreshold;

  form = {
    searchInput: '',
    projects: undefined,
  };
  projectLists = [];
  @Debounce(500)
  handleSearchSubmit() {
    this.pagination.current = 1;
    this.getTableList();
  }
  simpleImage;
  beforeCreate() {
    this.simpleImage = EmptyObj.PRESENTED_IMAGE_SIMPLE;
  }
  get columns() {
    return [
      {
        dataIndex: 'index',
        customRender: (text, record, index) => (
          <span>{(this.pagination.current - 1) * 10 + index + 1}</span>
        ),
        title: this.$t('safety.index'),
        fixed: 'left',
        align: 'center',
        width: 80,
      },
      {
        align: 'left',
        title: this.$t('safety.deviceName'),
        dataIndex: 'deviceName',
        ellipsis: true,
        width: 240,
      },
      {
        align: 'left',
        title: this.$t('safety.deviceCode'),
        dataIndex: 'deviceCode',
        ellipsis: true,
        width: 180,
      },
      {
        title: this.$t('safety.belongProject'),
        dataIndex: 'projectName',
        ellipsis: true,
        width: 240,
      },
      {
        align: 'left',
        title: this.$t('safety.lastDateTime'),
        dataIndex: 'lastDataTime',
        customRender: txt =>
          txt ? this.dayjs(txt).format('YYYY-MM-DD HH:mm:ss') : '',
      },
      {
        title: this.$t('common.remark'),
        dataIndex: 'remark',
      },
      {
        title: this.$t('common.operation'),
        fixed: 'right',
        key: 'operation',
        scopedSlots: { customRender: 'action' },
      },
    ];
  }
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '30', '40'],
    showTotal: total => `${total} ${this.$t('common.total')}`,
  };
  tableData = [];
  mounted() {
    this.getTableList();
    this.getProjectList();
  }
  get deviceGroup() {
    return deviceTypeEnum[this.pageType].deviceGroup;
  }
  get deviceType() {
    return deviceTypeEnum[this.pageType].deviceType;
  }
  async getProjectList() {
    try {
      this.projectLists = await projectList();
    } catch {
      return false;
    }
  }
  loading = false;
  async getTableList() {
    // 根据不同的设备类型调用接口 pageType
    const params = {
      // deviceType: this.deviceType,
      query: {
        current: this.pagination.current,
        size: this.pagination.pageSize,
      },
    };
    if (this.form.searchInput) {
      params.device = this.form.searchInput;
    }
    if (this.form.projects) {
      params.projectId = this.form.projects;
    }
    try {
      const { records, current, size, total } = await deviceList(
        params,
        this.deviceGroup,
      );
      this.tableData = records;
      this.pagination.current = current;
      this.pagination.pageSize = size;
      this.pagination.total = total;
    } catch {
      return false;
    }
  }
  handlePagination(data) {
    this.pagination = data;
    this.getTableList();
  }
  async addOrEditDevice(row, flag) {
    try {
      const result = await createFormModal(
        () => (
          <AddOrEditDevice
            editData={row}
            operationFlag={flag}
            pageType={this.pageType}
            projectList={this.projectLists}
          />
        ),
        {
          width: PublicSize.FormModalSize,
          title:
            flag === 'add'
              ? this.$t('safety.addDevice')
              : this.$t('safety.editDevice'),
        },
      );
      if (result) {
        await this.getTableList();
      }
    } catch {
      return false;
    }
  }
  async deleteTips(record) {
    const text = this.$t('safety.sureDeleteDevice');
    const tips = this.$t('safety.deleteDeviceTips');
    try {
      await createFormModal(
        () => (
          <Tip iconStyle={{ padding: '0 0 22px' }}>
            <template slot="txt">
              <span style="color: #F72F2F">{text}</span>
            </template>
            <span slot="subTxt">{tips}</span>
          </Tip>
        ),
        {
          width: '442px',
          title: this.$t('safety.importanceTips'),
          onOk: () => {
            this.deleteDevice(record.pkId);
          }, // 解绑 删除
        },
      );
    } catch {
      return false;
    }
  }
  // 删除设备
  async deleteDevice(id) {
    try {
      await deleteDeviceById(id, this.deviceGroup);
      const str = this.$t('safety.deleteSuccess');
      this.$message.success(str);
      if (this.pagination.current > 1) {
        if (this.tableData.length === 1) {
          this.pagination.current--;
        }
      }
      await this.getTableList();
    } catch {
      return false;
    }
  }
  modalDrawer = null;
  handleCloseModalDrawer() {
    this.modalDrawer.handleClosed();
  }
  showDetail(record) {
    this.$emit('showDetail', record);
  }
  async setThreshold(record) {
    try {
      const result = await createFormModal(
        () => <AddOrEditThreshold editData={record} pageType={this.pageType} />,
        {
          width: '442px',
          title: this.$t('safety.threshold'),
        },
      );
      if (result) {
        await this.getTableList();
      }
    } catch {
      return false;
    }
  }
}
</script>

<style lang="less" module>
.content {
  padding: 20px;
  .search {
    display: flex;
    justify-content: space-between;
    .item {
      display: inline-block;
    }
    .btn {
      > button {
        margin: 0 0 10px 10px;
      }
    }
    :global .ant-form-item {
      margin-bottom: 15px;
      .ant-form-item-label > label:after {
        content: ':';
        position: relative;
        top: -0.5px;
        margin: 0 8px 0 2px;
      }
    }
  }
  .buttonGroups {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: var(--primary);
    cursor: pointer;
    .red {
      color: red;
    }
    .button {
      margin-right: 15px;
      position: relative;
      .messageCount {
        position: absolute;
        font-size: 12px;
        top: 5px;
        right: 5px;
        height: 18px;
        width: 18px;
        line-height: 18px;
        border-radius: 50%;
        background: var(--font-active);
        color: var(--table-row);
        text-align: center;
      }
    }
  }
}
</style>
