<template>
  <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
    <a-form-model-item v-if="showFlag" :label="label[0].label" prop="dayMax">
      <a-input-number
        v-model="form.dayMax"
        :placeholder="label[0].inputLabel"
        style="width: 240px;"
        :min="0"
        :precision="2"
      />
      <span style="margin-left: 10px">{{ unit }}</span>
    </a-form-model-item>
    <a-form-model-item v-if="showFlag" :label="label[1].label" prop="monthMax">
      <a-input-number
        v-model="form.monthMax"
        :placeholder="label[1].inputLabel"
        style="width: 240px;"
        :min="0"
        :precision="2"
      />
      <span style="margin-left: 10px">{{ unit }}</span>
    </a-form-model-item>
    <a-form-model-item v-if="!showFlag" :label="label[0].label" prop="etMax">
      <a-input-number
        v-model="form.etMax"
        :placeholder="label[0].inputLabel"
        style="width: 240px;"
        :min="showFlag ? 0 : -50"
        :precision="showFlag ? 4 : 2"
      />
      <span style="margin-left: 10px">{{ unit }}</span>
    </a-form-model-item>
    <a-form-model-item v-if="showFlag" :label="label[2].label" prop="unitPrice">
      <a-input-number
        v-model="form.unitPrice"
        :placeholder="label[2].inputLabel"
        style="width: 240px;"
        :min="0"
        :precision="2"
      />
      <span style="margin-left: 10px">{{ `元/${unit}` }}</span>
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  setCtThreshold,
  setWaterThreshold,
} from '@/services/device-manage/device-detail';
import { deviceTypeEnum } from '@/enum/device';

@Component()
export default class AddEditThreshold extends Vue {
  @Prop({
    type: Object,
    default: () => {},
  })
  editData;
  @Prop({ type: String, default: '' }) pageType;

  form = {
    dayMax: '',
    monthMax: '',
    etMax: '',
    unitPrice: '',
  };
  get rules() {
    if (this.pageType !== 'CT') {
      return {
        dayMax: [
          {
            required: true,
            message: this.label[0].requiredLabel,
          },
        ],
        monthMax: [
          {
            required: true,
            message: this.label[1].requiredLabel,
          },
        ],
        unitPrice: [
          {
            required: true,
            message: this.label[2].requiredLabel,
          },
        ],
      };
    } else {
      return {
        etMax: [
          {
            required: true,
            message: this.label[0].requiredLabel,
          },
        ],
      };
    }
  }
  get unit() {
    const obj = {
      WM: 'm³',
      EM: '度',
      CT: '℃',
    };
    return obj[this.pageType];
  }
  get label() {
    const s1 = this.$t('safety.dailyWater');
    const s2 = this.$t('safety.inputDailyWater');
    const s3 = this.$t('safety.requiredDailyWater');
    const s4 = this.$t('safety.monthlyWater');
    const s5 = this.$t('safety.inputMonthlyWater');
    const s6 = this.$t('safety.requiredMonthlyWater');
    const s7 = '用水单价';
    const s8 = '请输入用水单价';
    const s9 = '用水单价不能为空';
    const e1 = this.$t('safety.dailyElec');
    const e2 = this.$t('safety.inputDailyElec');
    const e3 = this.$t('safety.requiredDailyElec');
    const e4 = this.$t('safety.MonthlyElec');
    const e5 = this.$t('safety.inputMonthlyElec');
    const e6 = this.$t('safety.requiredMonthlyElec');
    const e7 = '用电单价';
    const e8 = '请输入用电单价';
    const e9 = '用电单价不能为空';
    const t1 = this.$t('safety.warningTemperature');
    const t2 = this.$t('safety.inputTemperatureWarning');
    const t3 = this.$t('safety.requiredTwarning');
    const obj = {
      WM: [
        {
          label: s1,
          inputLabel: s2,
          requiredLabel: s3,
        },
        {
          label: s4,
          inputLabel: s5,
          requiredLabel: s6,
        },
        {
          label: s7,
          inputLabel: s8,
          requiredLabel: s9,
        },
      ],
      EM: [
        {
          label: e1,
          inputLabel: e2,
          requiredLabel: e3,
        },
        {
          label: e4,
          inputLabel: e5,
          requiredLabel: e6,
        },
        {
          label: e7,
          inputLabel: e8,
          requiredLabel: e9,
        },
      ],
      CT: [
        {
          label: t1,
          inputLabel: t2,
          requiredLabel: t3,
        },
      ],
    };
    return obj[this.pageType];
  }
  get showFlag() {
    return this.pageType !== 'CT';
  }
  mounted() {
    this.initData();
  }
  initData() {
    this.deviceId = '';
    const data = this.editData;
    this.deviceId = data.pkId;
    if (data.extData) {
      this.form.dayMax = data.extData.threshold.dayUseMax;
      this.form.monthMax = data.extData.threshold.monthUseMax;
      this.form.etMax = data.extData.threshold.temperature;
      this.form.unitPrice = data.extData.threshold.unitPrice;
    }
  }
  handleProjectChange(projectId) {
    this.form.projectId = projectId;
  }
  get deviceGroup() {
    return deviceTypeEnum[this.pageType].deviceGroup;
  }
  async getValue() {
    await this.$refs.form.validate();
    const params = {
      deviceId: this.deviceId,
    };
    if (this.pageType !== 'CT') {
      params.dayUseMax = this.form.dayMax;
      params.monthUseMax = this.form.monthMax;
      params.unitPrice = this.form.unitPrice;
    } else {
      params.threshold = this.form.etMax;
    }
    try {
      const str = this.$t('safety.setSuccess');
      if (this.showFlag) {
        await setWaterThreshold(params, this.deviceGroup);
      } else {
        await setCtThreshold(params, this.deviceGroup);
      }
      this.$message.success(str);
      return true;
    } catch {
      return false;
    }
  }
}
</script>

<style></style>
